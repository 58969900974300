import React, { Fragment } from "react";
import Container from "../components/container";
import SEO from "../components/seo";
import Icon from "../components/icon";
import Layout from "../containers/layout";
import { data, tintColor } from "../data";
import { GoMarkGithub } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { TiSocialTwitter } from "react-icons/ti";
import { IoLogoLinkedin } from "react-icons/io";

const styles = {
  space: {
    display: "inlineBlock",
    width: "2rem"
  },
  link: {
    color: tintColor,
    textDecoration: "underline",
    display: "inline-block"
  },
  smallText: {
    fontWeight: 300,
    fontSize: "0.8em"
  },
  subtitle: {
    fontSize: "1.75em",
    fontWeight: "bold",
    margin: "1em 0 0.25em"
  },
  socialItem: {
    display: "flex",
    padding: ".5em 0"
  },
  skillItem: {
    display: "flex",
    flexWrap: "wrap"
  },
  subtitle2: {
    fontSize: "1.125em",
    fontWeight: "normal",
    margin: "1em 0 0.25em"
  }
};

const emojis = {
  Github: <GoMarkGithub />,
  Twitter: <TiSocialTwitter />,
  Email: <MdEmail />,
  Platzi: <span />,
  Linkedin: <IoLogoLinkedin />
};

const CV = () => (
  <Fragment>
    <section id="section-skills" style={{ marginBottom: "3rem" }}>
      <h3 id="skills" style={styles.subtitle}>
        Skills
      </h3>
      <section>
        <article style={{ maxWidth: 500 }}>
          {data.skills.map(({ name, items }) => (
            <Fragment key={name}>
              <h6 id={name} style={styles.subtitle2}>
                {name}
              </h6>
              <div style={styles.skillItem}>
                {items.map(item => (
                  <small
                    key={item}
                    style={{ ...styles.smallText, marginRight: 8, color: "gray", fontSize: "1em" }}
                  >
                    {item} <div style={styles.space} />
                  </small>
                ))}
              </div>
            </Fragment>
          ))}
        </article>
      </section>
    </section>

    <section id="section-work" style={{ marginBottom: "3rem" }}>
      <h3 id="experience" style={styles.subtitle}>
        Experience
      </h3>
      {data.experience.map(({ name, items }) => (
        <article key={name} style={{ paddingTop: 8 }}>
          <h6
            id={name}
            style={{
              ...styles.subtitle,
              fontSize: "1.2em",
              fontWeight: "normal",
              margin: "1em 0 0.55em"
            }}
          >
            {name}
          </h6>
          {items.map(({ name, interval }, index) => (
            <div
              key={name}
              style={{
                display: "grid",
                gridTemplateAreas: `'dot position . date' 'line body body body'`,
                gridTemplateColumns: "25px 1fr 10px 160px",
                gridTemplateRows: "40px 1fr",
                alignItems: "center",
                maxWidth: 500,
                ...(index !== items.length - 1 && { height: 65 })
              }}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  background: tintColor,
                  borderRadius: "50%",
                  gridArea: "dot"
                }}
              />
              <span className="css-1u3z3lg">{name}</span>
              <em
                style={{
                  fontSize: "0.9em",
                  fontStyle: "normal",
                  fontWeight: 300,
                  gridArea: "date",
                  textAlign: "right"
                }}
              >
                {interval}
              </em>
              <div
                style={{
                  width: 3,
                  height: "100%",
                  background: tintColor,
                  marginLeft: 3,
                  gridArea: "line"
                }}
              />
              <br />
            </div>
          ))}
        </article>
      ))}
    </section>

    <section id="section-work" style={{ marginBottom: "3rem" }}>
      <h3 id="education" style={styles.subtitle}>
        Education
      </h3>
      {data.education.map(({ name, interval, description }) => (
        <article key={name}>
          <h6
            id={name}
            style={{
              ...styles.subtitle,
              fontSize: "1.2em",
              fontWeight: "normal",
              margin: "1em 0 0.25em"
            }}
          >
            {name}
          </h6>
          <div
            key={name}
            style={{
              display: "grid",
              gridTemplateAreas: `'dot position . date' 'line body body body'`,
              gridTemplateColumns: "25px 1fr 10px 160px",
              gridTemplateRows: "40px 1fr",
              alignItems: "center",
              maxWidth: 500
            }}
          >
            <span style={{ width: 200, color: "gray" }}>{description}</span>
            <em
              style={{
                fontSize: "0.9em",
                fontStyle: "normal",
                fontWeight: 300,
                gridArea: "date",
                textAlign: "right"
              }}
            >
              {interval}
            </em>
            <br />
          </div>
        </article>
      ))}
    </section>

    <section id="section-languages" style={{ marginBottom: "3rem" }}>
      <h3 id="languages" style={styles.subtitle}>
        Languages
      </h3>
      <section>
        {data.languages.map(({ language, level, url }) => (
          <article key={language}>
            <h6 id={`${language}--(-${level}-)`} style={styles.subtitle2}>
              {url ? (
                <a href={url} target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
                  <strong>{`${language} `}</strong>
                  <small style={styles.smallText}>{`(${level})`}</small>
                </a>
              ) : (
                <Fragment>
                  <strong>{`${language} `}</strong>
                  <small style={styles.smallText}>{`(${level})`}</small>
                </Fragment>
              )}
            </h6>
          </article>
        ))}
      </section>
    </section>
  </Fragment>
);

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Angel Rodríguez"
        description="Software Engineer & Entrepenur."
        keywords={["react native", "software", "engineer"]}
      />
      <Container>
        <div style={{ display: "flex", flexDirection: "column", alignSelf: "center" }}>
          <h1 className="typography-module--responsiveTitle1--1tLTC">Angel Rodríguez</h1>
          <blockquote
            style={{
              borderLeft: `3px solid ${tintColor}`,
              boxSizing: "border-box",
              paddingLeft: "calc(2em - 3px)",
              marginLeft: "-2em",
              marginRight: 0,
              fontWeight: "normal",
              fontSize: "1.3em",
              color: "gray",
              marginTop: 0
            }}
          >
            {data.subtitle}
          </blockquote>
          <div>
            {data.social.map(({ url, name, username }) => (
              <a
                key={url}
                href={url}
                title={username}
                target="_blank"
                style={{ display: "inline-block", marginRight: 7 }}
              >
                <Icon symbol={emojis[name]} />
              </a>
            ))}
            <a href={`mailto:${data.email}`} title={data.email} style={{ display: "inline-block" }}>
              <Icon symbol={emojis["Email"]} />
            </a>
          </div>
        </div>

        <CV />
      </Container>
    </Layout>
  );
};

export default IndexPage;
