import React from 'react'
import HamburgerIcon from './hamburger'

function Icon (props) {
  const {symbol} = props
  switch (symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    default:
      return symbol
  }
}

export default Icon
