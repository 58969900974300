export const tintColor = "#087E8B";
export const data = {
  name: "Angel Rodriguez",
  email: "angelmanuelrs at gmail dot com",
  subtitle: "Software Engineer & Entrepreneur.",
  social: [
    {
      url: "https://github.com/angel-rs",
      name: "Github",
      username: "@angel-rs",
    },
    {
      url: "https://www.linkedin.com/in/angel-rodriguez-7272a4196/",
      name: "Linkedin",
      username: "Angel Rodriguez",
    },
  ],
  languages: [
    {
      language: "Spanish",
      level: "Native speaker",
    },
    {
      language: "English",
      level: "C1",
    },
    {
      language: "French",
      level: "A1",
    },
  ],
  skills: [
    {
      name: "Frontend Development",
      items: ["React Native", "React", "Next.js", "Design systems", "State management libraries"],
    },
    {
      name: "Backend Development",
      items: [
        "Node.js",
        "MySQL",
        "PostgreSQL",
        "MongoDB",
        "Redis",
        "SQL",
        "NoSQL",
        "Next.js",
        "Serverless",
      ],
    },
    {
      name: "Languages",
      items: ["HTML", "CSS", "JavaScript", "TypeScript", "PHP", "C/C++", "Python", "Ruby", "Bash"],
    },
    {
      name: "Misc",
      items: [
        "Project management",
        "Agile methodologies",
        "Testing",
        "Web scraping",
        "Git",
        "RegExp",
        "Crypto",
      ],
    },
  ],
  experience: [
    {
      name: "Sitemate",
      items: [
        {
          name: "Technical Lead",
          interval: "2024 - Current",
        },
        {
          name: "Senior Software Engineer",
          interval: "2023 - 2024",
        },
      ],
    },
    {
      name: "Bridge",
      items: [
        {
          name: "Senior Software Engineer",
          interval: "2020 - 2023",
        },
      ],
    },
    {
      name: "Self-employed",
      items: [
        {
          name: "Freelancer",
          interval: "2018 - 2020",
        },
      ],
    },
    {
      name: "LCC Opentech",
      items: [
        {
          name: "Advisor",
          interval: "2019 - Current",
        },
        {
          name: "Analyst",
          interval: "2018 - 2019",
        },
        {
          name: "Full Stack Developer",
          interval: "2017 - 2018",
        },
        {
          name: "Frontend Developer",
          interval: "2016 - 2017",
        },
      ],
    },
  ],
  projects: [
    {
      name: "Ibis",
      description: "Payment assertion platform in Venezuela",
      url: "https://ibis.link",
      img: "https://landen.imgix.net/0npyou9o6mze/assets/k57hizag.png?w=264",
      technologies: ["React Native", "Next.js", "Node.js"],
    },
    {
      name: "Missy",
      description: "Online beauty shop",
      url: "https://missy.market",
      img: "https://missy.market/_next/static/images/logo-ef341448d211f2c9b35862d8d4e335b3.png",
      technologies: ["Headless Wordpress", "NextJS", "React"],
    },
    {
      name: "CryptoReference",
      description: "A different way to browse localbitcoins ads",
      url: "https://crypto-reference.com",
      img: require("./assets/projects/cryptoreference.png"),
      technologies: ["Express.js", "Preact", "CSS", "Bulma"],
    },
  ],
  education: [
    {
      name: "Universidad Catolica Andrés Bello",
      description: "Computer Science Engineer",
      interval: "2018 - 2021",
    },
    {
      name: "Universidad Nacional Experimental de Guayana",
      description: "Computer Science Engineer",
      interval: "2015 - 2018",
    },
  ],
};
